<template>
  <ProgressBar :start="start" :end="end" :position="nowUnixTime" />
</template>
<script>
export default {
  name: "ProgressBarLive",

  components: {
    ProgressBar: () => import("@/components/Progress-bar.vue"),
  },

  props: {
    start: { type: Number, required: true },
    end: { type: Number, required: true },
  },

  data() {
    return {
      timer: null,
      nowUnixTime: 0,
    };
  },

  mounted() {
    const self = this;

    const actualizarProgreso = function () {
      self.nowUnixTime = Date.now() / 1000;
    };

    //La primera vez actualizo inmediatamente, después actualizo cada 10s
    actualizarProgreso();
    this.timer = setInterval(actualizarProgreso, 10000);
  },

  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>
